import { once } from 'lodash';
import { bindActionCreators } from 'redux';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { fileUploaded, fileDownloaded } from '@wix/communities-blog-client-common/dist/src/actions/bi';

import { clearComments } from '../store/comments/clear-comments';
import { createCommentPromisified } from '../store/comments/create-comment';
import { fetchComments, fetchCommentsPromisified } from '../store/comments/fetch-comments';
import { fetchFeedPostsPromisified } from '../actions/fetch-feed-posts';
import { fetchCategoryPostsPromisified } from '../actions/fetch-category-posts';
import { fetchExperiments } from '../store/experiments/experiments-actions';
import { fetchTranslations } from '../store/translations/translations-actions';
import { fetchUser } from '../actions/fetch-user';
import { fetchCurrentUser } from '../actions/fetch-current-user';
import { getCurrentUser } from '../store/current-user/current-user-selectors';
import { incrementCommentLikeCount } from '../store/comment-likes/comment-likes-actions';
import { incrementPostLikeCount } from '../store/post-likes/post-likes-actions';
import { initialiseInstanceValues } from '../store/instance-values/instance-values-actions';
import { initializeMembersNavigation } from '../store/ma-navigation/ma-navigation-actions';
import { setAppSettings } from '../store/app-settings/app-settings-actions';
import { setBasicParams } from '../store/basic-params/basic-params-actions';
import { setCommunitiesContext } from '../store/communities-context/communities-context-actions';
import { setHasReportedAppLoaded } from '../store/app-loaded/app-loaded-actions';
import { setIsLoaded } from '../store/is-loaded/is-loaded-actions';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import { setLocale } from '../store/locale/locale-actions';
import { setSavedAction } from '../store/saved-action/set-saved-action';
import { setUserAgent } from '../store/user-agent/user-agent-actions';
import { setViewMode } from '../store/view-mode/view-mode-actions';
import { showHeaderLogin } from '../store/header-widget/show-header-login';
import { startEditingComment } from '../store/comments/start-editing-comment';
import { stopEditingComment } from '../store/comments/stop-editing-comment';
import { updateCommentPromisified } from '../store/comments/update-comment';
import { deleteComment } from '../store/comments/delete-comment';
import fetchCategories from '../store/categories/fetch-categories';
import trackEvent from '../actions/track-event';

import { BUTTON_ACTION_CLICK } from '../bi-events/bi-buttons';

import { actionsClosed } from '../actions/actions-closed';
import { actionsOpened } from '../actions/actions-opened';
import { appLoaded } from '../actions/app-loaded';
import { buttonClicked } from '../actions/button-clicked';
import { createPostDraftPromisified } from '../actions/create-post-draft';
import { headerSearchOpened, headerSearchClosed } from '../actions/header-search';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { navigateWithinBlog, navigateWithinPostPage } from '../actions/navigate-within-blog';
import { pageOpened } from '../actions/page-opened';
import { previewPost } from '../actions/preview-post';
import { publishPostPromisified } from '../actions/publish-post';
import { requestLoginPromisified, requestLogin } from '../actions/request-login';
import { savePostDraftPromisified } from '../actions/save-post-draft';
import { showJoinCommunityPopup } from '../actions/join-community';
import { openPostSettings } from '../store/post-settings/open-post-settings';
import { subscribePromisified } from '../actions/subscribe';
import { unsubscribePromisified } from '../actions/unsubscribe';
import { unpinPostPromisified } from '../actions/unpin-post';
import { pinPostPromisified } from '../actions/pin-post';
import { disablePostCommentsPromisified } from '../../comments/actions/disable-post-comments';
import { enablePostCommentsPromisified } from '../../comments/actions/enable-post-comments';
import { uploadImagePromisified } from '../actions/upload-image-new';
import { setActivePostSettingsTab } from '../store/tabs/set-active-tab';
import { fetchMediaToken, setEnableLinkfify } from '../store/editor-config/editor-config-actions';

import { modalActions } from '../modals/framework/initialize-store';
import { messageActions } from '../messages/framework/initialize-store';

import { fastFormSubmitCallback } from '../components/fast-form/store/fast-form-actions';
import { requestFileUploadUrlPromisified, requestFileDownloadUrlPromisified } from '../actions/wix-media';

import { getUserBySiteMemberId } from '../selectors/user-selectors';
import { setUser } from '../actions/set-user';
import { resolveUser, getEnvironment } from './helpers';
import { setAppConfig } from '../store/app-config/app-config-actions';
import { fetchAppData } from '../store/app-data/app-data-actions';
import { fetchSiteProperties } from '../store/site-properties/site-properties-actions';
import { fetchTopology } from '../store/topology/topology-actions';
import { getMetaSiteId } from '../store/instance-values/instance-values-selectors';

export function initializeActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }) {
  return {
    interactionStarted: interaction => fedopsLogger.interactionStarted(interaction),
    interactionEnded: interaction => fedopsLogger.interactionEnded(interaction),

    navigateToSection: section =>
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => api.navigateToSection(section)),
    navigateToMember: memberId =>
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => api.navigateToMember(memberId)),
    logout: () => wixCodeApi.user.logout(),
    navigateToRoot: () => {
      wixCodeApi.location.to('/');
    },
    navigateToProfile: siteMemberIdOrSlug => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api.navigateToMember({ memberId: siteMemberIdOrSlug }).catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    renderSEOTags: data => wixCodeApi.seo.renderSEOTags(data),
    ...bindActionCreators(
      {
        appLoaded: once(appLoaded(fedopsAppLoaded)),
        showJoinCommunityPopup,
        navigateWithinBlog,
        navigateWithinPostPage,
        fastFormSubmitCallback,
        setHasReportedAppLoaded,
        requestLogin,
        setSavedAction,
        actionsOpened,
        actionsClosed,
        fetchComments,
        clearComments,
        startEditingComment,
        stopEditingComment,
        incrementCommentLikeCount,
        deleteComment,
        incrementPostLikeCount,
        incrementPostViewCount,
        buttonClicked: data => buttonClicked({ name: BUTTON_ACTION_CLICK, type: 'post', ...data }),
        goToFeed: () => navigateWithinBlog('/'),
        gotoCreatePost: () => navigateWithinBlog('/create-post'),
        goToEditPage: id => navigateWithinPostPage(`/${id}/edit`),
        ...modalActions,
        ...messageActions,
        pageOpened,
        cancelUploads: () => console.log('cancelUploads'),
        previewPost,
        openPostSettings,
        setActivePostSettingsTab,
        headerSearchOpened,
        headerSearchClosed,
        showHeaderLogin,
        goBack: () => console.log('goBack'), // TODO: fix or remove
        setIsLoading,
        setIsLoaded,
        fileUploaded,
        fileDownloaded,
        trackEvent,
      },
      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions({ store }) {
  return {
    ...bindActionCreators(
      {
        requestFileUploadUrlPromisified,
        requestFileDownloadUrlPromisified,
        requestLoginPromisified,
        createCommentPromisified,
        updateCommentPromisified,
        uploadImagePromisified,
        savePostDraftPromisified,
        publishPostPromisified,
        subscribePromisified,
        unsubscribePromisified,
        pinPostPromisified,
        unpinPostPromisified,
        enablePostCommentsPromisified,
        disablePostCommentsPromisified,
        duplicatePostPromisified: createPostDraftPromisified,
        fetchCommentsPromisified,
        fetchFeedPostsPromisified,
        fetchCategoryPostsPromisified,
      },
      store.dispatch,
    ),
  };
}

export async function initializeStoreBaseData({
  wixCodeApi,
  appParams,
  store,
  language,
  platformAPIs,
  config,
  sectionUrl,
  bundleName,
  fedopsAppName,
}) {
  const instanceId = appParams.instanceId;
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';

  store.dispatch(initialiseInstanceValues(appParams.instance));

  const metaSiteId = getMetaSiteId(store.getState());

  await Promise.all([
    initUserDependentStoreBaseData({ store, wixCodeApi }),
    store.dispatch(fetchAppData(metaSiteId)),
    store.dispatch(fetchSiteProperties(metaSiteId)),
    store.dispatch(fetchExperiments(metaSiteId)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(initializeMembersNavigation()),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(fetchTranslations(language)),
    store.dispatch(setViewMode(viewMode)),
    store.dispatch(setEnableLinkfify(instanceId)),
    store.dispatch(setUserAgent({ source: userAgent })),
    store.dispatch(fetchCategories(metaSiteId)),
    store.dispatch(setLocale(language)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ sectionUrl, bundleName, fedopsAppName }));
}

export async function initUserDependentStoreBaseData({ store, wixCodeApi }) {
  const user = resolveUser(wixCodeApi);
  store.dispatch(initialiseInstanceValues(user.instance));

  if (!user.loggedIn) {
    return Promise.resolve();
  }

  const { isEditor, isPreview } = getEnvironment(wixCodeApi);
  const shouldFetchCurrentUser = !isEditor && !isPreview;

  return Promise.all([
    store.dispatch(shouldFetchCurrentUser ? fetchCurrentUser() : fetchUser(user.id)),
    store.dispatch(fetchMediaToken()),
  ]).then(([currentUser]) =>
    store.dispatch(setUser(shouldFetchCurrentUser ? currentUser : getUserBySiteMemberId(store.getState(), user.id))),
  );
}

export function refreshDataOnLogin({ wixCodeApi, store, router, pathname, doRouting }) {
  wixCodeApi.user.onLogin(() =>
    setTimeout(() => {
      initUserDependentStoreBaseData({ store, wixCodeApi }).then(() => doRouting({ store, router, pathname }));
    }),
  );
}
